import * as React from 'react'
import TwoFASetupCodeForm from '@/templates/TwoFASetupCodeForm'
import { Location } from '@reach/router';

function TwoFACodeSetup() {
	return (
		<Location>
			{({ location }) => {
				return <TwoFASetupCodeForm location={location} />
			}}
		</Location>
	)
}

export default TwoFACodeSetup
