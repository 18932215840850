import * as React from 'react'
import { useState, useRef, useEffect } from 'react'
import { func, bool, string } from 'prop-types'

export const AuthCodeForm = ({ setPreparedCode, isError, wrapperClassName, onEnter }) => {
	const [code, setCode] = useState(Array.from({ length: 6 }));
	const ref = useRef([]);

	const inputIds = ['code-0', 'code-1', 'code-2', 'code-3', 'code-4', 'code-5'];

	useEffect(() => {
		if (ref.current[0]) {
			ref.current[0].focus();
		}
	}, []);

	useEffect(() => {
		setPreparedCode(code?.map((item) => item || 0).join(''))
	}, [code]);

	const generateUniqueName = () => {
		const timestamp = Date.now();

		return `input-${timestamp}`;
	};

	const handleNextInput = (e) => {
		const fieldIndex = +e.target.id.split('-')[1];
		const copy = [...code];

		copy[fieldIndex] = +e.target.value;
		setCode(copy);
		ref?.current[fieldIndex + 1]?.focus();
	};

	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			if (onEnter && code.every((item) => item !== undefined)) {
				e.preventDefault();
				onEnter();
			} else {
				e.preventDefault();
			}
		}
	};

	return (
		<div className={`flex w-full mt-md mb-2xl ${wrapperClassName}`}>
			{
				inputIds.map((id, index) => (
					<div
						key={id}
						className='w-50 h-50 mr-md'
					>
						<input
							key={id}
							id={id}
							type="text"
							placeholder="_"
							maxLength="1"
							autocomplete="off"
							name={generateUniqueName()}
							ref={(inputId) => (ref.current[index] = inputId)}
							onChange={(e) => handleNextInput(e)}
							onKeyDown={(e) => handleKeyDown(e)}
							className={`px-xs text-center w-full h-full border ${isError ? 'border-s-error-bg' : 'border-grey-40'} rounded-10`}
						/>
					</div>
				))
			}
		</div>
	)
}

AuthCodeForm.propTypes = {
	setPreparedCode: func.isRequired,
	onEnter: func,
	isError: bool,
	wrapperClassName: string
}
